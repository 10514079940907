import React from "react";
import Simon from "./../../assets/images/simon.png";
import Neal from "./../../assets/images/neal.png";
import Parth from "./../../assets/images/parth.png";
import Lisa from "./../../assets/images/lisa.png";
import Joe from "./../../assets/images/joe.png";
import Tom from "./../../assets/images/tom.png";
import Elena from "./../../assets/images/elena.png";
import George from "./../../assets/images/george.png";

const TeamSection = () => {
  const teamMembers = [
    {
      image: Simon,
      name: "Simon Babakhani",
      position: "Founder/Managing Partner",
    },
    {
      image: Neal,
      name: "Neal Padhye",
      position: "Partner",
    },
    {
      image: Elena,
      name: "Elena Solovyov",
      position: "Chief Marketing Officer",
    },
    {
      image: Parth,
      name: "Parth Shah",
      position: "Associate",
    },
    {
      image: Lisa,
      name: "Lisa Robbins",
      position: "Advisor",
    },
    {
      image: Joe,
      name: "Joe Chevalier",
      position: "Advisor",
    },
    {
      image: Tom,
      name: "Tom Lutz",
      position: "Advisor",
    },
    {
      image: George,
      name: "George Stoeckert",
      position: "Advisor",
    },
  ];
  return (
    <>
      <div className="container">
        <div className="pb-16">
          <h2 className="text-heading-1-mob md:text-heading-1 text-primary">
            {" "}
            Meet our team{" "}
          </h2>
        </div>
        <div className="max-w-[635.21px] pb-20">
          <p className=" text-body-text-small text-primary">
            Our team has led over $2BN in investment and acquisition-based
            growth strategies.
          </p>
        </div>
        <div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-x-40 gap-y-14">
            {/* 1st image */}
            {teamMembers.map((member) => {
              return (
                <div>
                  <div className="pb-6">
                    <img
                      src={member.image}
                      alt="Simon Babakhani"
                      className="object-cover person-img"
                    />
                  </div>
                  <div>
                    <h6 className="text-body-text-exsmall text-primary_blue">
                      {member.name}
                    </h6>
                    <p className="text-body-text-exsmall">{member.position}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="border-line-team"></div>
    </>
  );
};

export default TeamSection;
